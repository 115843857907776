import { lazy } from "react";

//ValidationButtons
const ValidationButtons = lazy(() =>
  import("../views/validation-buttons/ValidationButtons")
);
const Button = lazy(() => import("../views/validation-buttons/Button"));
const ButtonSetting = lazy(() =>
  import("../views/validation-buttons/ButtonSetting")
);
const ReqDoc = lazy(() => import("../views/validation-buttons/ReqDoc"));
//Requests Sent
const NewRequest = lazy(() => import("../views/Requests-sent/NewRequest"));
// const Pending = lazy(() => import("../views/Requests-sent/Pending"));
//const Completed = lazy(() => import("../views/Requests-sent/Completed"));

const UserDashboard = lazy(() => import("../views/users/UserDashboard"));

//Documents Recieved
const ReviewPending = lazy(() =>
  import("../views/documents-recieved/ReviewPending")
);
const Approved = lazy(() => import("../views/documents-recieved/Approved"));
const Rejected = lazy(() => import("../views/documents-recieved/Rejected"));
// const Pdf = lazy(() => import("../views/documents-recieved/Pdf"));

//See coverage
const SeeCoverage = lazy(() => import("../views/see-coverage/SeeCoverage"));

//Developers
const ApiReference = lazy(() => import("../views/developers/ApiReference"));
const ApiIntegration = lazy(() => import("../views/developers/ApiIntegration"));

const NoCodePage = lazy(() => import("../views/developers/nocode/NoCodePage"));
const SetupOrganization = lazy(() =>
  import("../views/developers/nocode/SetupOrganization")
);
const SetupVerificationButton = lazy(() =>
  import("../views/developers/nocode/SetupVerificationButton")
);
const TriggerVerification = lazy(() =>
  import("../views/developers/nocode/TriggerVerification")
);
const AfterDocumentSubmission = lazy(() =>
  import("../views/developers/nocode/AfterDocumentSubmission")
);
const ApiIntegrationFordev = lazy(() =>
  import("../views/developers/nocode/ApiIntegrationFordev")
);
const BeforeGoLive = lazy(() =>
  import("../views/developers/nocode/BeforeGoLive")
);

// const Settings = lazy(() => import("../views/developers/Settings"));
const Logs = lazy(() => import("../views/developers/Logs"));

// const Form = lazy(() => import("../views/developers/Form"));
//Manage Account
const Users = lazy(() => import("../views/manage-account/Users"));
const Billing = lazy(() => import("../views/manage-account/Billing"));
const OrganizationsDetail = lazy(() =>
  import("../views/manage-account/OrganizationalDetail")
);
//const userRoles = ls.get('roles');

//const TestImage = lazy(() => import("../views/test/TestImage"));

var ThemeRoutes = [
  {
    path: "/client/admin-dash/button-setting/:id",
    name: "New Validation buttons",
    icon: "check-square",
    component: ButtonSetting,
  },

  {
    path: "/client/admin-dash/req-document/:id",
    name: "New Validation buttons",
    icon: "check-square",
    component: ReqDoc,
  },

  {
    path: "/client/validation-buttons/button-setting/:id",
    name: "New Validation buttons",
    icon: "check-square",
    component: ButtonSetting,
  },

  {
    path: "/client/validation-buttons/req-document/:id",
    name: "New Validation buttons",
    icon: "check-square",
    component: ReqDoc,
  },
  {
    path: "/client/user-dashboard",
    name: "User Dashboard",
    icon: "check-square",
    component: UserDashboard,
  },
  {
    path: "/client/admin-dash",
    name: "Validation buttons",
    icon: "check-square",
    component: Button,
  },
  {
    path: "/client/validation-buttons",
    name: "Validation buttons",
    icon: "check-square",
    component: Button,
  },

  {
    path: "/client/dashboard",
    name: "New Validation buttons",
    icon: "check-square",
    component: ValidationButtons,
  },
  {
    path: "/client/button-setting/:id",
    name: "New Validation buttons",
    icon: "check-square",
    component: ButtonSetting,
  },

  {
    path: "/client/req-document/:id",
    name: "New Validation buttons",
    icon: "check-square",
    component: ReqDoc,
  },

  {
    collapse: true,
    path: "/client/request-sent",
    name: "Requests sent",
    state: "dashboardpages",
    icon: "home",
    child: [
      {
        path: "/client/request-sent/new-request",
        name: "New request",
        mini: "B",
        icon: "mdi mdi-adjust",
        component: NewRequest,
      },
      // {
      //   path: "/client/request-sent/pending",
      //   name: "Pending",
      //   mini: "B",
      //   icon: "mdi mdi-adjust",
      //   component: Pending,
      // },
      // {
      //   path: "/client/request-sent/completed",
      //   name: "Completed",
      //   mini: "B",
      //   icon: "mdi mdi-adjust",
      //   component: Completed,
      // },
    ],
  },
  {
    collapse: true,
    path: "/client/documents-received",
    name: "Documents received",
    state: "uicomponents",
    icon: "cpu",
    child: [
      {
        path: "/client/documents-received/review-pending",
        name: "Review pending",
        icon: "mdi mdi-comment-processing-outline",
        component: ReviewPending,
      },
      {
        path: "/client/documents-received/approved",
        name: "Approved",
        icon: "mdi mdi-arrange-send-backward",
        component: Approved,
      },
      {
        path: "/client/documents-received/rejected",
        name: "Rejected",
        icon: "mdi mdi-equal",
        component: Rejected,
      },
    ],
  },
  // {
  //   path: "/client/see-coverage",
  //   name: "See-coverage",
  //   icon: "check-square",
  //   component: SeeCoverage,
  //   target: "_blank",
  // },

  {
    collapse: true,
    path: "/client/see-coverage",
    name: "See coverage",
    state: "formlayoutPages",
    icon: "check-square",
    child: [
      {
        path: "/client/see-coverage",
        name: "See coverage",
        icon: "check-square",
        component: SeeCoverage,
        target: "_blank", 
      },
    ]
  },

  {
    collapse: true,
    path: "/client/developers",
    name: "Developers",
    state: "formlayoutPages",
    icon: "file-text",
    child: [
      {
        path: "/client/developers/no-code-integration",
        name: "No Code Integration",
        icon: "mdi mdi-priority-low",
        component: NoCodePage,
      },
      {
        path: "/client/developers/setup-organization",
        name: "Setup organization",
        icon: "mdi mdi-priority-low",
        component: SetupOrganization,
      },
      {
        path: "/client/developers/setup-verification-button",
        name: "Setup verification buttons",
        icon: "mdi mdi-priority-low",
        component: SetupVerificationButton,
      },
      {
        path: "/client/developers/trigger-verifications",
        name: "Trigger verification",
        icon: "mdi mdi-priority-low",
        component: TriggerVerification,
      },
      {
        path: "/client/developers/after-document-submission",
        name: "After document submission",
        icon: "mdi mdi-priority-low",
        component: AfterDocumentSubmission,
      },
      {
        path: "/client/developers/for-dev-api-integration",
        name: "After document submission",
        icon: "mdi mdi-priority-low",
        component: ApiIntegrationFordev,
      },
      {
        path: "/client/developers/before-go-live",
        name: "Before you go live",
        icon: "mdi mdi-priority-low",
        component: BeforeGoLive,
      },

      {
        path: "/client/developers/api-refrence",
        name: "API reference",
        icon: "mdi mdi-priority-low",
        component: ApiReference,
      },
      {
        path: "/client/developers/api-reference",
        name: "API reference",
        icon: "mdi mdi-priority-low",
        component: ApiReference,
      },
      {
        path: "/client/developers/api-refrence/:id",
        name: "API reference",
        icon: "mdi mdi-priority-low",
        component: ApiReference,
      },
      {
        path: "/client/developers/api-reference/:id",
        name: "API reference",
        icon: "mdi mdi-priority-low",
        component: ApiReference,
      },

      {
        path: "/client/developers/api-integration",
        name: "API & Integration",
        icon: "mdi mdi-priority-low",
        component: ApiIntegration,
      },
      {
        path: "/client/developers/logs",
        name: "Logs",
        icon: "mdi mdi-select-all",
        component: Logs,
      },
    ],
  },

  // {
  //   path: "/client/test-image",
  //   name: "New Validation buttons",
  //   icon: "check-square",
  //   component: TestImage,
  // },

  {
    collapse: true,
    path: "/client/manage-account",
    name: "Manage account",
    state: "formpickerPages",
    icon: "droplet",
    child: [
      {
        path: "/client/manage-account/users",
        name: "Users",
        icon: "mdi mdi-calendar-clock",
        component: Users,
      },
      {
        path: "/client/manage-account/billing",
        name: "Billing",
        icon: "mdi mdi-tag-multiple",
        component: Billing,
      },
      {
        path: "/client/manage-account/organizations-details",
        name: "Organizations details",
        icon: "mdi mdi-tag-multiple",
        component: OrganizationsDetail,
      },
    ],
  },
];
export default ThemeRoutes;
