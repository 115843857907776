import React, { useState, Suspense, useEffect } from "react";
// import React, {  Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import Header from "./layout-components/header/Header";
import Sidebar from "./layout-components/sidebar/Sidebar";
import ThemeRoutes from "../routes/Router";
import Spinner from "../views/spinner/Spinner";
import Alert from "./Alert";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";

const FullLayout = (props) => {
	const [width, setWidth] = useState(window.innerWidth);
	const [sidebartype, setSidebartype] = useState(null);
	const [delAlert, setDelAlert] = useState({ num: 0, title: null, msg: null });
	//const [width] = useState(window.innerWidth);
	const settings = useSelector((state) => state.settings);
	
	console.log(settings, "settings=================");
	useEffect(() => {
		const updateDimensions = () => {
			// let element = document?.getElementById("main-wrapper");
			setWidth(window.innerWidth);
			switch (settings.activeSidebarType) {
				case "full":
				case "iconbar":
					// if(element) {
						if (width < 1170) {
							// element.setAttribute("data-sidebartype", "mini-sidebar");
							// element.classList.add("mini-sidebar");
							setSidebartype("mini-sidebar");
						} else {
							// element.setAttribute("data-sidebartype", "full");
							// element.classList.remove("mini-sidebar");
							setSidebartype("full");
						}
					// }
					
					break;

				default:
			}
		};

		if (document.readyState === "complete") {
			updateDimensions();
		}
		window.addEventListener("resize", updateDimensions.bind(this));
		window.addEventListener("load", updateDimensions.bind(this));
		return () => {
			window.removeEventListener("load", updateDimensions.bind(this));
			window.removeEventListener("resize", updateDimensions.bind(this));
		};
	}, [settings, settings.activeSidebarType, width]);

	useEffect(() => {
		setDelAlert(props.delButton);
	},[props.delButton]);
	const gotIT = () => {
		setDelAlert({ num: 0, title: null, msg: null })
	}

	const url = window.location.href;

	return (
		<div
			id="main-wrapper"
			dir={settings.activeDir}
			data-theme={settings.activeTheme}
			data-layout={settings.activeThemeLayout}
			data-sidebartype={sidebartype}
			data-sidebar-position={settings.activeSidebarPos}
			data-header-position={settings.activeHeaderPos}
			data-boxed-layout={settings.activeLayout}
			className={sidebartype === "mini-sidebar" ? "mini-sidebar" : ""}
		>
			{/*--------------------------------------------------------------------------------*/}
			{/* Header                                                                         */}
			{/*--------------------------------------------------------------------------------*/}
			<Header url={url} />
			<Alert />
			{/*--------------------------------------------------------------------------------*/}
			{/* Sidebar                                                                        */}
			{/*--------------------------------------------------------------------------------*/}
			<Sidebar {...props} onClick={url} routes={ThemeRoutes} />
			{/*--------------------------------------------------------------------------------*/}
			{/* Page Main-Content                                                              */}
			{/*--------------------------------------------------------------------------------*/}
			<div className="page-wrapper d-block" style={{ paddingTop: "80px" }}>
				<div className="page-content container-fluid">
					<Suspense fallback={<Spinner />}>
						<Switch>
							{ThemeRoutes.map((prop, key) => {
								if (prop.navlabel) {
									return null;
								} else if (prop.collapse) {
									return prop.child.map((prop2, key2) => {
										if (prop2.collapse) {
											return prop2.subchild.map((prop3, key3) => {
												return <Route path={prop3.path} component={prop3.component} key={key3} />;
											});
										}
										return <Route path={prop2.path} component={prop2.component} key={key2} />;
									});
								} else if (prop.redirect) {
									return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
								} else {
									return <Route path={prop.path} component={prop.component} key={key} />;
								}
							})}
						</Switch>
					</Suspense>
					{ delAlert?.num === 1 ? <SweetAlert
						success
						title={delAlert.title}
						onConfirm={gotIT}
					>
						{delAlert.msg}
					</SweetAlert> : delAlert?.num === 3 ? <SweetAlert
						danger
						title={delAlert.title}
						confirmBtnBsStyle="danger"
						onConfirm={gotIT}
					>
						{delAlert.msg}
					</SweetAlert> : ""}
				</div>
			</div>
			{/*--------------------------------------------------------------------------------*/}
			{/* Customizer from which you can set all the Layout Settings                      */}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	);
};
const mapStateToProps = (state) => ({
	delButton: state.button.btnSettingDelBtn
});

// export default FullLayout;
export default connect(mapStateToProps, null)(FullLayout);