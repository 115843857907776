import {
    AUTH_ERROR,
    REMOVE_AUTH_ERROR
  } from '../actions/types';
  
  const initialState = {
    error_modal:false,
    errorMsg:''
  };
  
  export default function (state = initialState, action) {
    const { type,payload } = action;
    switch (type) {
      case AUTH_ERROR:
        return{
          ...state,
          error_modal:true,
          errorMsg:payload
        }

        case REMOVE_AUTH_ERROR:
          return{
            ...state,
            error_modal:false,
            errorMsg:''
          }
      default:
        return state;
    }
  
  }
  