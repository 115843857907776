import React, { useEffect } from "react";
import Routes from "./routes/Routes";
import "./App.css";
import { GlobalDebug } from "./remove-consoles";
import { env as environment } from "./actions/environment";
// import { TrackJS } from 'trackjs'

// if (process.env.NODE_ENV === 'production') {
//   console.log = function () {};
// }
// TrackJS.install({ token: '6970616068344e9b959e5ac97bbd3fd6' });

const App = () => {
  useEffect(() => {
    if (!environment.consoleLog) {
      GlobalDebug(false);
    }
  }, []);
  return (
    <div>
      <Routes />
    </div>
  );
};

export default App;
