import {
  GET_REQUESTS_HEADER,
  GET_PENDINGS_HEADER,
  GET_COMPLETED_HEADER,
  GET_REVIEWS_HEADER,
  GET_APPROVES_HEADER,
  GET_REJECTS_HEADER,
  GET_VALIDATION_HEADER,
  GET_COVERAGE_HEADER,
  GET_LOGS_HEADER,
  GET_REFERENCE_HEADER,
  GET_USER_HEADER,
  GET_BILLING_HEADER,
  GET_ORG_HEADER,
  GET_INTEGRATION_HEADER,
  GET_BUTTON_SETTING_HEADER,
  GET_VIEW_DOC_HEADER,
  GET_TRIGGER_HEADER,
  FOR_DEVELOPERS_NOTIFICATION_HEADER,
  AFTER_DOCUMENT_SUBMISSION_HEADER,
  GET_SETUP_VERIFICATION_BTN_HEADER,
  GET_SETUP_ORGANIZATION_HEADER,
  GET_NOCODE_INTEGRATION_HEADER,
  BEFORE_YOU_GO_LIVE,
  USER_PERMISION_LANDING,
  CONFIGURE_VERIFICATION,
  DURING_ADVANCE_VERIFICATION,
  TRIGGER_COMPARE_VERIFICATION,
  DELETE_DOC
} from "../actions/types";

const initialState = {
  subheading: "",
  search: false,
  adduser: false,
  addbutton: false,
  buttonSettingbut: false,
  reqdocbut: false,
  button_id: null,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_REQUESTS_HEADER:
      return {
        subheading: "New Request",
        search: true,
      };

    case GET_PENDINGS_HEADER:
      return {
        subheading: "Pending",
        search: true,
      };

    case GET_COMPLETED_HEADER:
      return {
        subheading: "Completed",
        search: true,
      };

    case GET_REVIEWS_HEADER:
      return {
        subheading: "Review pending",
        search: true,
      };

    case GET_APPROVES_HEADER:
      return {
        subheading: "Approved",
        search: true,
      };

    case GET_REJECTS_HEADER:
      return {
        subheading: "Rejected",
        search: true,
      };

    case GET_VALIDATION_HEADER:
      return {
        subheading: "Request document",
        addbutton: true,
      };

    case GET_COVERAGE_HEADER:
      return {
        subheading: "See coverage",
      };

    case GET_LOGS_HEADER:
      return {
        subheading: "Callback logs",
        search: true,
      };

    case GET_REFERENCE_HEADER:
      return {
        subheading: "API reference",
      };

    case GET_USER_HEADER:
      return {
        subheading: "User details",
        adduser: true,
      };

    case GET_BILLING_HEADER:
      return {
        subheading: "Billing details",
      };

    case GET_ORG_HEADER:
      return {
        subheading: "Organization details",
      };
    case GET_INTEGRATION_HEADER:
      return {
        subheading: "Integration Documentation",
      };
    case GET_BUTTON_SETTING_HEADER:
      return {
        subheading: "Button settings",
        buttonSettingbut: true,
        button_id: payload,
      };

    case GET_VIEW_DOC_HEADER:
      return {
        subheading: "Request document",
        reqdocbut: true,
        button_id: payload,
      };

    case GET_TRIGGER_HEADER:
      return {
        subheading: "Step 2 - Trigger verification (no-code)",
      };
    case GET_NOCODE_INTEGRATION_HEADER:
      return {
        subheading: "Integration",
      };
    case GET_SETUP_ORGANIZATION_HEADER:
      return {
        subheading: "Setup organization",
      };
    case GET_SETUP_VERIFICATION_BTN_HEADER:
      return {
        subheading: "Step 1  - Setup verification buttons (no-code)",
      };
    case AFTER_DOCUMENT_SUBMISSION_HEADER:
      return {
        subheading: "Step 3  - After document submission (no-code)",
      };
    case FOR_DEVELOPERS_NOTIFICATION_HEADER:
      return {
        subheading: "For developers  - API integration (optional)",
      };
    case BEFORE_YOU_GO_LIVE:
      return {
        subheading: "Before you go live!",
      };
      case USER_PERMISION_LANDING:
        return{
          subheading:"User permision"
        };
      case CONFIGURE_VERIFICATION :
        return{
          subheading:"Configure Verification fields "
         };
        
       case DURING_ADVANCE_VERIFICATION:
        return {
          subheading:"During Advance Verification"
        }
        case TRIGGER_COMPARE_VERIFICATION:
          return{
           subheading:" Trigger Verification"
          } 
          case DELETE_DOC:
            return{
              subheading:"Delete Document"
            }
    
    default:
      return state;
  }
}
