import {
	GET_ORG_ITEM,
	CLEAR_ORG,

	//UPDATE_ORG,
} from "../actions/types";
import ls from "localstorage-slim";

ls.config.encrypt = true;

const initialState = {
	requestorg: "",
	nickname: ls.get("nickname"),
	flatamt: ls.get("flat_amount"),
	orgdetails: null,
	orgstripe_id: null,
	loading: true,
	nicknamedata: [],
	usage: 0,
	error: {},
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_ORG_ITEM:
			ls.remove("nickname");
			ls.remove("flat_amount");
			//console.log("nickname" + payload.subscriptions.data[0].plan.nickname);
			// if(payload.subscriptions.data)
			// {
			//     ls.set('nickname', payload.subscriptions.data[0].plan.nickname);
			// }
			//console.log(payload.subscriptions.data[0].plan.tiers[0].flat_amount);
			// if(payload.subscriptions.data)
			// {
			//     //ls.set('flat_amount', payload.subscriptions.data[0].plan.tiers[0].flat_amount);
			// }
			//console.log(payload.id);
			ls.set("orgstripe_id", payload.id);
			//console.log(payload);
			return {
				...state,
				...payload,
				requestorg: payload,
				usage: payload.usage,
				orgstripe_id: payload.id,
				nicknamedata: payload.nickname ? payload.nickname : [],
				// flatamt:payload.subscriptions.data[0].plan.tiers[0].flat_amount,
				loading: false,
			};

		case CLEAR_ORG:
			return {
				...state,
				orgdetails: null,
				loading: true,
			};
		// case UPDATE_ORG:
		//     //console.log("update org " + payload.data);
		//     return {
		//         ...state,
		//         ...payload,
		//         orgdetails: payload.data,
		//         loading: false
		//     }
		default:
			return state;
	}
}
