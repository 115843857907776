import axios from "axios";

import {
	GET_TRANSACTION,
	GET_TRANSACTIONS,
	TRANSACTION_ERROR,
	GET_BILLING_HEADER,
	// GET_BILLING,
	BILLING_ERROR,
} from "./types";
import { env as environment } from "./environment";
import refreshAuth from "./refreshAuth";
import ls from "localstorage-slim";

ls.config.encrypt = true;
//var stripeid = ls.get('stripeid')
axios.defaults.headers.common["Authorization"] = ls.get("token");

export const geturltransactions = (stripeid) => async (dispatch) => {
	dispatch({ type: GET_BILLING_HEADER });

	var json = { customerid: ls.get("stripeid") };
	try {
		const res = await axios.post(environment.transactions, json);

		dispatch({
			type: GET_TRANSACTION,
			payload: res.data,
		});
	} catch (err) {
		if (err.response) {
			if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
				dispatch(refreshAuth("geturltransactions", stripeid));
			}
		} else {
			dispatch({
				type: TRANSACTION_ERROR,
				payload: err.response,
			});
		}
	}
};

export const gettransactions = (orgstripe_id) => async (dispatch) => {
	dispatch({ type: GET_BILLING_HEADER });

	var json = { customerid: orgstripe_id };
	try {
		const res = await axios.post(environment.transactions, json);

		dispatch({
			type: GET_TRANSACTIONS,
			payload: res.data,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("gettransactions", orgstripe_id));
		} else {
			dispatch({
				type: TRANSACTION_ERROR,
				payload: err.response,
			});
		}
	}
};

let redUsrCount = 0;
export const redirectuser = (id) => async (dispatch) => {
	let json = { customerid: id, returnurl: environment.returnurl };
	try {
		const res = await axios.post(environment.striperedirect, json);

		if (res.data.url) {
			redUsrCount = 0;
			window.open(res.data.url, "_blank");
		} else {
		}
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error") && redUsrCount < 5) {
			redUsrCount++;
			dispatch(refreshAuth("redirectuser", id));
		} else {
			redUsrCount = 0;
			dispatch({
				type: BILLING_ERROR,
				// payload: err.response
			});
		}
	}
};
