import React from "react";

const Footer = () => {
	const date = new Date();
	const year = date.getFullYear();
	return (
		<footer style={{ fontSize: "12px", width: "90%", marginInline: "auto", marginTop: "25px", color: "#a9a3a3" }}>
			© 2014-{year} DIRO All Rights Reserved
		</footer>
	);
};
export default Footer;
