import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import Reducers from "./reducers";

export function configureStore(initialState) {
  const composeEnhancers = composeWithDevTools({
    trace: true, 
    traceLimit: 25 
}); 
// const store = createStore(reducer, preloadedState, composeEnhancers(
//     applyMiddleware(invariant(), thunk) 
// ));
  const store = createStore(
    Reducers,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
}
