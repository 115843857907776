import { lazy } from "react";
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const BlankLayout = lazy(() => import("../layouts/BlankLayout"));
// const FullAuthLayout = lazy(() => import("../layouts/FullAuthLayout"));
//const Pdf = lazy(() => import("../views/documents-recieved/Pdf.js"));
const PdfView = lazy(() => import("../views/documents-recieved/PdfView.js"));
const PdfViewDoc = lazy(() => import("../views/documents-recieved/PdfViewDoc.js"));

const SessionDetails = lazy(() => import("../views/documents-recieved/Sessiondetails"));

var indexRoutes = [
	{ path: "/viewdoc/:file", name: "viewdoc", component: PdfViewDoc },
	{ path: "/pdf/:file", name: "pdf", component: PdfView },
	{ path: "/pdf-view/:file", name: "pdf", component: PdfView },
	{ path: "/client", name: "Dashboard", component: FullLayout },
	{ path: "/client", name: "Dashboard", component: FullLayout },
	{ path: "/session-details/:file", name: "SessionDetails", component: SessionDetails },
	{ path: "/", name: "Athentication", component: BlankLayout },
];

export default indexRoutes;
