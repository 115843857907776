import {
    GET_TRANSACTION,
    GET_TRANSACTIONS,
    TRANSACTION_ERROR,
    GET_BILLING,
    BILLING_ERROR
} from '../actions/types'

const initialState = {
    transaction: null,
    transactions:[],
    loading: false,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_TRANSACTION:
            return {
                ...state,
                transaction: payload.data,
                loading: false
            }
        case GET_TRANSACTIONS:
            return {
                ...state,
                transaction: payload.data,
                loading: false
            }    

        case TRANSACTION_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        case GET_BILLING:
            return {
                ...state,
                transaction: payload.data,
                loading: false
            }
        case BILLING_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state
    }
}