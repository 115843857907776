export const config = {
    clientID: '9cb662c8-0e64-41eb-b8cc-dd8244d12e02',
    clientSecret:"aIeniT-kIQhdlyM0h6_BsZEEyCna8qCXGoUts_zx728",
    serverUrl:"https://fauth2.diro.live",
    redirectUri: 'https://client2.diro.live/redirect-page',
    tenentId:"84f65a70-9b87-fea9-0f00-302b2597bf03",
    applicationId:"9cb662c8-0e64-41eb-b8cc-dd8244d12e02",
  }




