import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import Spinner from "./views/spinner/Spinner";
import "./assets/scss/style.scss";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from '@sentry/integrations';
import App from "./app";
import { Provider } from "react-redux";
import { configureStore } from "./Store";
import { env as environment } from "./actions/environment";
import {FusionAuthProvider} from "@fusionauth/react-sdk";
import {config} from './config.js'
// if (process.env.NODE_ENV === 'production') {
//   console.log = function () {};
// }
// import TimeAgo from 'javascript-time-ago'
// import en from 'javascript-time-ago/locale/en.json'
// import ru from 'javascript-time-ago/locale/ru.json'
// TimeAgo.addDefaultLocale(en)
// TimeAgo.addLocale(ru)

// if (environment.env === "stage1") {
//     console.log = () => undefined;
//     console.debug = () => undefined;
//     console.info = () => undefined;
//     console.warn = () => undefined;
// }

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: environment.sentryDSN,
		integrations: [
			new Sentry.BrowserTracing(),
			new CaptureConsole({
				levels: ['error']
			})
		],
		// for finer control adjust this value in production, or using tracesSampler
		tracesSampleRate: 1.0,
	});
}



ReactDOM.render(
	<Provider store={configureStore()}>
		<Suspense fallback={<Spinner />}>
		<FusionAuthProvider {...config}>
			<App />
			</FusionAuthProvider>
		</Suspense>
		,
	</Provider>,
	document.getElementById("root")
);
