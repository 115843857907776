import {
    GET_TEST_PREVIEW,
    GET_SEND_REQUEST,
    GET_INVITE_LINK,
    REQ_ERROR,
    REQ_LOADER,
    EMPTY_BLANK_URL
  } from '../actions/types';

  const initialState = {
  
    req : null,
    showurl: false,
    mailsend:false,
    verification_url:'',
    blank_url:'',
    error_msg:false,
    req_loading:false,
    error : {}
  };

  export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

    case EMPTY_BLANK_URL:  
    return {
        blank_url:'',
    } 
 
    case GET_TEST_PREVIEW:
        window.open(payload.verificationlink);
       return {
             ...state,
            ...payload,
             blank_url:payload.verificationlink,
             showurl: false,
             mailsend:false,
            req_loading: false,
            error_msg:false,
       };
 
    case GET_SEND_REQUEST:
        return {
            ...state,
            ...payload,
            req:payload.message,
            req_loading: false,
            blank_url:'',
            mailsend:true,
            error_msg:false,
        };   
    
    case GET_INVITE_LINK:
       return {
            ...state,
            ...payload,
            req:payload.data, 
            showurl: true,
            verification_url:payload.verificationlink,
            blank_url:'',
            mailsend:false,
            req_loading: false,
            error_msg:false,
       };  
    case REQ_ERROR:
        return {
            ...state,
            ...payload,
            //req:payload.data, 
            blank_url:'',
            showurl: false,
            mailsend:false,
            error_msg:true,
            req_loading: false
        } 
    case REQ_LOADER: 
    return {
        req_loading: true
    }       

       default:
          return state;
    
       }
    
     }