import axios from "axios";
import ls from "localstorage-slim";

ls.config.encrypt = true;
const setAuthToken = async(token) => {
	if (token)
		if (ls.get("token")) {

			console.log(token,'here is the token inside the setAuthToken method')
			// if (token === "superadmin") {
			// 	//alert("token " + token);
			// 	axios.defaults.headers.common["Authorization"] =
			// 		"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkaXJvbGFic0BnbWFpbC5jb20iLCJhcGlrZXkiOiIzNjE1NmNjODM5YmE5NzI3MmJjNDI2ZDc5YzcyYTQxZiIsImlhdCI6MTY1MTY3MDkzOX0.17jQ1btOzBpCObAxC7SBkI9rmXJML7j1UH_oJpVxcvb2tOHlXisQ3ouM6Tst9GjnuDIoF7rvpkqBRhGDu8Q4rQ";
			// } else {
			// 	 alert("2 " + token);
			// 	axios.defaults.headers.common["Authorization"] = token;
			// }
			//  alert("hii")
			//alert("3 " );
			// axios.defaults.headers.common["Authorization"] = await ls.get("token");
			axios.defaults.headers.common["Authorization"] = ls.get('token');
		} else {
			//alert("token " + token + ls.get('token'));
			delete axios.defaults.headers.common["Authorization"];
		}
};

export default setAuthToken;
