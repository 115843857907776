import React, { useState, Fragment } from "react";
import { useSelector, connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import {
  Nav,
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
//import { apiref } from "../../../actions/button";
import FeatherIcon from "feather-icons-react";
import Footer from "../footer/Footer";
import PropTypes from "prop-types";
import { submitFeedbackData } from "../../../actions/table";
import { logout } from "../../../actions/auth";
import { env as environment } from "../../../actions/environment";
// import { HashLink } from "react-router-hash-link";
import ModeTonggle from './ModeTonggle.js';
import ls from "localstorage-slim";

ls.config.encrypt = true;
const Sidebar = (
  {
    isAuthenticated,
    submitFeedbackData,
    roles,
    location,
    logout,
    header: { subheading },
    auth: { email },
  },
  props
) => {
  console.log(subheading,"subheadingfor sidebar");
  //console.log(isAuthenticated);
  // useEffect(() => {
  //   apiref();

  // }, [apiref],10000);
  const [reportModal, setReportModal] = useState(false);
  const [ticketSubmit, setTicketSubmit] = useState(false);
  const activeRoute = (routeName) => {
    //console.log(routeName + location.pathname.indexOf(routeName));
    return location.pathname.indexOf(routeName) > -1 ? "selected" : "";
  };
  // const [hashactive, setActiveHash] = useState({
  //   nocode: false,
  //   step1: false,
  //   step2: false,
  //   step3: false,
  // });

  const [state, setState] = useState({
    validationbuttons:
      activeRoute("/client/validation-buttons") !== "" ? true : false,
    Requests: activeRoute("/client/request-sent") !== "" ? true : false,
    Documents: activeRoute("/client/documents-received") !== "" ? true : false,
    Developer: activeRoute("/client/developers") !== "" ? true : false,
    Account: activeRoute("/client/manage-account") !== "" ? true : false,
    seeCoverage: activeRoute("/client/see-coverage")!== "" ? true : false,
  });
  const [formData, setFormData] = useState({
    emailId: ls.get("email"),
    comment: "",
    source: "client portal",
  });
  const [err, seterror] = useState({
    comment: "",
  });

  // const [cstate, csetState] = useState({
  //   apiReference:
  //     activeRoute("/client/developers/api-reference") !== "" ? true : false,
  // });

  // const setRequests =() => {
  //     setState({
  //        Requests : !state.Requests
  //     })
  //  }

  const setDocuments = () => {
    setState({
      Documents: !state.Documents,
    });
  };

  const setDeveloper = () => {
    setState({
      Developer: !state.Developer,
    });

    // csetState({
    //   apiReference: false,
    // });
  };

  // const setApiReference = () => {
  //   csetState({
  //     apiReference: !cstate.apiReference,
  //   });
  // };

  const setAccount = () => {
    setState({
      Account: !state.Account,
    });
  };

  // const setseeCoverage = () =>{
  //   setState({
  //     seeCoverage: !state.seeCoverage,
  //   })
  // }
  const settings = useSelector((state) => state.settings);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
    // location.reload();
  }

  
  const expandLogo = () => {
    document.getElementById("logobg").classList.toggle("expand-logo");
  };
  

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // setActiveHash({
    //   nocode: false,
    //   step1: false,
    //   step2: false,
    //   step3: false,
    // });
    // csetState({
    //   apiReference: false,
    // });
  };

  const showMobilemenu = () => {
    if (window.innerWidth < 800) {
      document.getElementById("main-wrapper").classList.toggle("show-sidebar");
    }
  };

 
  const authLinks = (
    <NavLink
      to="#"
      className="sidebar-link"
      activeclassname="active"
      onClick={logout}
    >
      <i className="mdi mdi-comment-processing-outline" />
      <span className="hide-menu">Logout</span>
    </NavLink>
  );

  const guestLinks = (
    <NavLink
      to="/authentication/login"
      className="sidebar-link"
      activeclassname="active"
      onClick={showMobilemenu}
    >
      <i className="mdi mdi-comment-processing-outline" />
      <span className="hide-menu">Login</span>
    </NavLink>
  );

  const handleValidation = () => {
    //console.log("handle validation")
    let fields = formData;
    let errors = {};
    let formIsValid = true;

    //first Name

    if (fields["comment"].length === 0) {
      formIsValid = false;
      //setCharError(true);
      errors["comment"] = "This field is required";
    }

    //console.log("err " +  errors.firstName);
    seterror({
      comment: errors.comment,
    });
    //console.log(err.errors);
    return formIsValid;
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const closeReportModal = () => {
    setReportModal(false);
    setTicketSubmit(false);
    seterror({ comment: "" }); // If the model is off, the comment box should be empty.
  };
  const openReportModal = (x) => {
    setReportModal(true);
  };
  const onReportSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const FeedData = {
        email: ls.get("email"),
        comment: formData.comment,
        sessionId: "",
        source: "client portal",
      };

      // submitFeedbackData(FeedData, true);

    submitFeedbackData(FeedData, false); 
      setFormData({ ...formData, comment: "" });
      setTicketSubmit(true);

      // setReportModal(false);
    }
  };

  return (
    <aside
      className="left-sidebar"
      id="sidebarbg"
      data-sidebarbg={settings.activeSidebarBg}
      onMouseEnter={expandLogo.bind(null)}
      onMouseLeave={expandLogo.bind(null)}
    >
      <div className="scroll-sidebar">
        {roles !== "Account" ? (
          <PerfectScrollbar className="sidebar-nav h-90">
            {/*--------------------------------------------------------------------------------*/}
            {/* Sidebar Menus will go here                                                */}
            {/*--------------------------------------------------------------------------------*/}
            <Nav id="sidebarnav" style={{ paddingTop: "40px" }}>
              {/* *************validation button ***********************/}
              {/* {email !== "dirolabs@gmail.com" ? ( */}
              <li
                onClick={scrollTop}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    scrollTop();
                  }
                }}
                className={
                  activeRoute("/client/validation-buttons") +
                  ("Validation" ? " active active-pro" : "") +
                  " sidebar-item"
                }
                key="/client/validation-buttons"
                role="button"
                tabIndex={0}
              >
                <a
                  href="/client/validation-buttons"
                  onClick={showMobilemenu}
                  className="sidebar-link"
                  activeclassname="active"
                  // target={prop.target}
                >
                  <FeatherIcon icon="check-square" />
                  {/* <i className={prop.icon} /> */}
                  <span className="hide-menu">Verification buttons</span>
                </a>
              </li>

              <li
                onClick={scrollTop}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    scrollTop();
                  }
                }}
                className={
                  activeRoute("/client/request-sent") +
                  ("Validation" ? " active active-pro" : "") +
                  " sidebar-item"
                }
                key="/client/request-sent"
                role="button"
                tabIndex={0}
              >
                <a
                  href="/client/request-sent/new-request"
                  onClick={showMobilemenu}
                  className="sidebar-link"
                  activeclassname="active"
                  // target={prop.target}
                >
                  <FeatherIcon icon="command" />
                  {/* <i className={prop.icon} /> */}
                  <span className="hide-menu">Requests sent</span>
                </a>
              </li>

              <li
                className={
                  activeRoute("/client/documents-received") + " sidebar-item"
                }
                key="/client/documents-received"
              >
                <span
                  data-toggle="collapse"
                  className="sidebar-link has-arrow"
                  aria-expanded={state.Documents}
                  onClick={() => setDocuments()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      setDocuments();
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <FeatherIcon icon="grid" />
                  {/* <i className={prop.icon} /> */}
                  <span className="hide-menu">Documents received</span>
                </span>
                <Collapse isOpen={state.Documents}>
                  <ul className="first-level">
                    {/* Review pending */}
                    <li
                      onClick={scrollTop}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          scrollTop();
                        }
                      }}
                      className={
                        activeRoute(
                          "/client/documents-received/review-pending"
                        ) +
                        ("/client/documents-received/review-pending"
                          ? " active active-pro"
                          : "") +
                        " sidebar-item"
                      }
                      key="/client/documents-received/review-pending"
                      role="button"
                      tabIndex={0}
                    >
                      <NavLink
                        to="/client/documents-received/review-pending"
                        className="sidebar-link"
                        activeclassname="active"
                        onClick={showMobilemenu}
                      >
                        <i className="mdi mdi-comment-processing-outline" />
                        <span className="hide-menu">Review pending</span>
                      </NavLink>
                    </li>
                    {/* Approved */}
                    <li
                      onClick={scrollTop}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          scrollTop();
                        }
                      }}
                      className={
                        activeRoute("/client/documents-received/approved") +
                        ("/client/documents-received/approved"
                          ? " active active-pro"
                          : "") +
                        " sidebar-item"
                      }
                      key="/client/documents-received/approved"
                      role="button"
                      tabIndex={0}
                    >
                      <NavLink
                        to="/client/documents-received/approved"
                        className="sidebar-link"
                        activeclassname="active"
                        onClick={showMobilemenu}
                      >
                        <i className="mdi mdi-comment-processing-outline" />
                        <span className="hide-menu">Approved</span>
                      </NavLink>
                    </li>
                    {/* Rejected */}
                    <li
                      onClick={scrollTop}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          scrollTop();
                        }
                      }}
                      className={
                        activeRoute("/client/documents-received/rejected") +
                        ("/client/documents-received/rejected"
                          ? " active active-pro"
                          : "") +
                        " sidebar-item"
                      }
                      key="/client/documents-received/rejected"
                      role="button"
                      tabIndex={0}
                    >
                      <NavLink
                        to="/client/documents-received/rejected"
                        className="sidebar-link"
                        activeclassname="active"
                        onClick={showMobilemenu}
                      >
                        <i className="mdi mdi-comment-processing-outline" />
                        <span className="hide-menu">Rejected</span>
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
              {/* *************Document recieved ends here ***********************/}
              {/* *************See coverage***********************/}
              <li
                onClick={scrollTop}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    scrollTop();
                  }
                }}
                className={
                  activeRoute(environment.seeCoverage) +
                  ("Validation" ? " active active-pro" : "") +
                  " sidebar-item"
                }
                key={environment.seeCoverage}
                role="button"
                tabIndex={0}
              >
                <a
                  href={environment.seeCoverage}
                  onClick={showMobilemenu}
                  className="sidebar-link"
                  activeclassname="active"
                  target="blank"
                >
                  <FeatherIcon icon="globe" />
                  {/* <i className={prop.icon} /> */}
                  <span className="hide-menu">See coverage</span>
                </a>
              </li>
              {/* *************See coverageends here ***********************/}

              {/* *************Developers starts here ***********************/}
              {roles !== "User" || roles !== "Account" ? (
                <li
                  className={
                    activeRoute("/client/developers") + " sidebar-item"
                  }
                  key="/client/developers"
                >
                  <a
                    href="/client/developers/no-code-integration"
                    data-toggle="collapse"
                    className="sidebar-link has-arrow"
                    aria-expanded={state.Developer}
                    onClick={() => setDeveloper()}
                  >
                    <FeatherIcon icon="code" />
                    {/* <i className={prop.icon} /> */}
                    <span className="hide-menu">Integration</span>
                  </a>
                  <Collapse isOpen={state.Developer}>
                    <ul className="first-level">
                      <li
                        onClick={scrollTop}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            scrollTop();
                          }
                        }}
                        className={
                          activeRoute("/client/developers/logs") +
                          ("/client/developers/logs"
                            ? " active active-pro"
                            : "") +
                          " sidebar-item"
                        }
                        key="/client/developers/logs"
                        role="button"
                        tabIndex={0}
                      >
                        <NavLink
                          to="/client/developers/logs"
                          className="sidebar-link "
                          activeclassname="active"
                          onClick={showMobilemenu}
                        >
                          <i className="mdi mdi-comment-processing-outline" />
                          <span className="hide-menu">Callback logs</span>
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>
              ) : (
                <li
                  onClick={scrollTop}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      scrollTop();
                    }
                  }}
                  className="disable sidebar-item"
                  key="#developers"
                  role="button"
                  tabIndex={0}
                >
                  <a
                    href="#!"
                    onClick={showMobilemenu}
                    className="sidebar-link link-disabled"
                    activeclassname="disabled"
                    style={{ color: "#a9a3a3", pointerEvents: "none" }}
                  >
                    <FeatherIcon
                      style={{ color: "#a9a3a3" }}
                      icon="check-square"
                    />
                    {/* <i className={prop.icon} /> */}
                    <span className="hide-menu">Developers</span>
                  </a>
                </li>
              )}

              {/* *************Developers ends here ***********************/}

              {/* *************Manage account starts here ***********************/}
              <li
                className={
                  activeRoute("/client/manage-account") + " sidebar-item"
                }
                key="/client/manage-account"
              >
                <span
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      setAccount();
                    }
                  }}
                  data-toggle="collapse"
                  className="sidebar-link has-arrow"
                  aria-expanded={state.Account}
                  onClick={() => setAccount()}
                >
                  <FeatherIcon icon="settings" />
                  {/* <i className={prop.icon} /> */}
                  <span className="hide-menu">Manage account</span>
                </span>
                <Collapse isOpen={state.Account}>
                  <ul className="first-level">
                    {/* Userd */}
                    {roles !== "User" ||
                    roles !== "Manager" ||
                    roles !== "Account" ? (
                      <li
                        onClick={scrollTop}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            scrollTop();
                          }
                        }}
                        className={
                          activeRoute("/client/manage-account/users") +
                          ("/client/manage-account/users"
                            ? " active active-pro"
                            : "") +
                          " sidebar-item"
                        }
                        key="/client/manage-account/users"
                        role="button"
                        tabIndex={0}
                      >
                        <NavLink
                          to="/client/manage-account/users"
                          className="sidebar-link"
                          activeclassname="active"
                          onClick={showMobilemenu}
                        >
                          <i className="mdi mdi-comment-processing-outline" />
                          <span className="hide-menu">Users</span>
                        </NavLink>
                      </li>
                    ) : (
                      <li
                        onClick={scrollTop}
                        className="sidebar-item"
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            scrollTop();
                          }
                        }}
                        key="#user_blank"
                        role="button"
                        tabIndex={0}
                        style={{ color: "#a9a3a3" }}
                      >
                        <NavLink
                          to="#"
                          className="sidebar-link link-disabled"
                          activeclassname="active"
                          onClick={showMobilemenu}
                          style={{ color: "#a9a3a3", pointerEvents: "none" }}
                        >
                          <i className="mdi mdi-comment-processing-outline" />
                          <span className="hide-menu">Users</span>
                        </NavLink>
                      </li>
                    )}

                    {/* Billing */}
                    <li
                      onClick={scrollTop}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          scrollTop();
                        }
                      }}
                      className={
                        activeRoute("/client/manage-account/billing") +
                        ("/client/manage-account/billing"
                          ? " active active-pro"
                          : "") +
                        " sidebar-item"
                      }
                      key="/client/manage-account/billing"
                      role="button"
                      tabIndex={0}
                    >
                      <NavLink
                        to="/client/manage-account/billing"
                        className="sidebar-link"
                        activeclassname="active"
                        onClick={showMobilemenu}
                      >
                        <i className="mdi mdi-comment-processing-outline" />
                        <span className="hide-menu">Billing</span>
                      </NavLink>
                    </li>
                    {/* Organizations details */}
                    <li
                      onClick={scrollTop}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          scrollTop();
                        }
                      }}
                      className={
                        activeRoute(
                          "/client/manage-account/organizations-details"
                        ) +
                        ("/client/manage-account/organizations-details"
                          ? " active active-pro"
                          : "") +
                        " sidebar-item"
                      }
                      key="/client/manage-account/organizations-details"
                      role="button"
                      tabIndex={0}
                    >
                      <NavLink
                        to="/client/manage-account/organizations-details"
                        className="sidebar-link"
                        activeclassname="active"
                        onClick={showMobilemenu}
                      >
                        <i className="mdi mdi-comment-processing-outline" />
                        <span className="hide-menu">Organization details</span>
                      </NavLink>
                    </li>

                    {/*Logout*/}
                    <li
                      onClick={scrollTop}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          scrollTop();
                        }
                      }}
                      className="sidebar-item"
                      key="#logout"
                      role="button"
                      tabIndex={0}
                    >
                      <Fragment>
                        {isAuthenticated ? authLinks : guestLinks}
                      </Fragment>
                    </li>
                  </ul>
                </Collapse>
              </li>
              {/* *************Manage account ends here ***********************/}

              <li className="sidebar-item">
                <span
                  className="sidebar-link"
                  activeclassname="active"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      openReportModal();
                    }
                  }}
                  onClick={openReportModal}
                  role="button"
                >
                  <FeatherIcon
                    icon="message-square"
                    className="report-issue-icon bg-transparent"
                    // style={{ color: "#a9a3a3" }}
                  />
                  <span className="hide-menu">Report issue</span>
                </span>
              </li>

              {/* ========test mode btn ================== */}
              <li className=" sidebar-item " >
                <span
                  className="sidebar-link "
                  activeclassname="active"              

                  role="button"
                  style={{ marginTop: "-6px", zIndex: 100 }}
                >
                  {" "}
                  <ModeTonggle />
                </span>
              </li>
              {/* ============== */}
            </Nav>
            {/* <ModeTonggle/>  */}
          </PerfectScrollbar>
        ) : (
          <PerfectScrollbar className="sidebar-nav">
            {/*--------------------------------------------------------------------------------*/}
            {/* Sidebar Menus will go here                                                */}
            {/*--------------------------------------------------------------------------------*/}
            <Nav id="sidebarnav" style={{ paddingTop: "40px" }}>
              {/* *************validation button ***********************/}

              <li
                onClick={scrollTop}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    scrollTop();
                  }
                }}
                className="disable sidebar-item"
                key="#developers"
                tabIndex={0}
                role="button"
              >
                <a
                  href="#!"
                  onClick={showMobilemenu}
                  className="sidebar-link link-disabled"
                  activeclassname="disabled"
                  style={{ color: "#a9a3a3", pointerEvents: "none" }}
                >
                  <FeatherIcon
                    style={{ color: "#a9a3a3" }}
                    icon="check-square"
                  />
                  {/* <i className={prop.icon} /> */}
                  <span className="hide-menu">Validation buttons</span>
                </a>
              </li>

              {/* *************validation button ends here ***********************/}
              {/* *************Request sent starts here ***********************/}

              <li
                onClick={scrollTop}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    scrollTop();
                  }
                }}
                className="disable sidebar-item"
                key="#developers"
                tabIndex={0}
                role="button"
              >
                <a
                  href="#!"
                  onClick={showMobilemenu}
                  className="sidebar-link link-disabled"
                  activeclassname="disabled"
                  style={{ color: "#a9a3a3", pointerEvents: "none" }}
                >
                  <FeatherIcon style={{ color: "#a9a3a3" }} icon="command" />
                  {/* <i className={prop.icon} /> */}
                  <span className="hide-menu">Requests sent</span>
                </a>
              </li>
              {/* *************Request sent ends here ***********************/}
              {/* *************Document recieved starts here ***********************/}

              <li
                onClick={scrollTop}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    scrollTop();
                  }
                }}
                tabIndex={0}
                className="disable sidebar-item"
                key="#developers"
                role="button"
              >
                <a
                  href="#!"
                  onClick={showMobilemenu}
                  className="sidebar-link link-disabled"
                  activeclassname="disabled"
                  style={{ color: "#a9a3a3", pointerEvents: "none" }}
                >
                  <FeatherIcon style={{ color: "#a9a3a3" }} icon="grid" />
                  {/* <i className={prop.icon} /> */}
                  <span className="hide-menu">Documents received</span>
                </a>
              </li>
              {/* *************Document recieved ends here ***********************/}
              {/* *************See coverage***********************/}
              <li
                onClick={scrollTop}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    scrollTop();
                  }
                }}
                tabIndex={0}
                className={
                  activeRoute(environment.seeCoverage) +
                  ("Validation" ? " active active-pro" : "") +
                  " sidebar-item"
                }
                key={environment.seeCoverage}
                role="button"
              >
                <a
                  href={environment.seeCoverage}
                  onClick={showMobilemenu}
                  className="sidebar-link"
                  activeclassname="active"
                  target="blank"
                >
                  <FeatherIcon icon="globe" />
                  {/* <i className={prop.icon} /> */}
                  <span className="hide-menu">See coverage</span>
                </a>
              </li>
              {/* *************See coverageends here ***********************/}

              {/* *************Developers starts here ***********************/}

              <li
                onClick={scrollTop}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    scrollTop();
                  }
                }}
                tabIndex={0}
                className="disable sidebar-item"
                key="#developers"
                role="button"
              >
                <a
                  href="#!"
                  onClick={showMobilemenu}
                  className="sidebar-link link-disabled"
                  activeclassname="disabled"
                  style={{ color: "#a9a3a3", pointerEvents: "none" }}
                >
                  <FeatherIcon
                    style={{ color: "#a9a3a3" }}
                    icon="check-square"
                  />
                  {/* <i className={prop.icon} /> */}
                  <span className="hide-menu">Developers</span>
                </a>
              </li>

              {/* *************Developers ends here ***********************/}

              {/* *************Manage account starts here ***********************/}
              <li
                className={
                  activeRoute("/client/manage-account") + " sidebar-item"
                }
                key="/client/manage-account"
              >
                <span
                  data-toggle="collapse"
                  className="sidebar-link has-arrow"
                  aria-expanded={state.Account}
                  onClick={() => setAccount()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      setAccount();
                    }
                  }}
                  tabIndex={0}
                  role="button"
                >
                  <FeatherIcon icon="settings" />
                  {/* <i className={prop.icon} /> */}
                  <span className="hide-menu">Manage account</span>
                </span>
                <Collapse isOpen={state.Account}>
                  <ul className="first-level">
                    {/* Userd */}

                    <li
                      onClick={scrollTop}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          scrollTop();
                        }
                      }}
                      className="sidebar-item"
                      key="#user_blank"
                      style={{ color: "#a9a3a3" }}
                      tabIndex={0}
                      role="button"
                    >
                      <NavLink
                        to="#"
                        className="sidebar-link link-disabled"
                        activeclassname="active"
                        onClick={showMobilemenu}
                        style={{ color: "#a9a3a3", pointerEvents: "none" }}
                      >
                        <i className="mdi mdi-comment-processing-outline" />
                        <span className="hide-menu">Users</span>
                      </NavLink>
                    </li>

                    {/* Billing */}
                    <li
                      onClick={scrollTop}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          scrollTop();
                        }
                      }}
                      className={
                        activeRoute("/client/manage-account/billing") +
                        ("/client/manage-account/billing"
                          ? " active active-pro"
                          : "") +
                        " sidebar-item"
                      }
                      key="/client/manage-account/billing"
                      tabIndex={0} // Make the li focusable
                      role="button"
                    >
                      <NavLink
                        to="/client/manage-account/billing"
                        className="sidebar-link"
                        activeclassname="active"
                        onClick={showMobilemenu}
                      >
                        <i className="mdi mdi-comment-processing-outline" />
                        <span className="hide-menu">Billing</span>
                      </NavLink>
                    </li>
                    {/* Organizations details */}
                    {/* <li onClick={scrollTop} className= "sidebar-item"
                  key="#user_blank-1" style={{color:"#a9a3a3"}}>
                  <NavLink
                    to="#"
                    className="sidebar-link link-disabled"
                    activeclassname="active"
                    onClick={showMobilemenu}
                    style={{color:"#a9a3a3", pointerEvents: "none"}}
                  >
                    <i className="mdi mdi-comment-processing-outline" />
                    <span className="hide-menu">Organization details</span>
                  </NavLink>
            </li> */}

                    <li
                      onClick={scrollTop}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          scrollTop();
                        }
                      }}
                      className={
                        activeRoute(
                          "/client/manage-account/organizations-details"
                        ) +
                        ("/client/manage-account/organizations-details"
                          ? " active active-pro"
                          : "") +
                        " sidebar-item"
                      }
                      key="/client/manage-account/organizations-details"
                      tabIndex={0} // Make the li focusable
                      role="button"
                    >
                      <NavLink
                        to="/client/manage-account/organizations-details"
                        className="sidebar-link"
                        activeclassname="active"
                        onClick={showMobilemenu}
                      >
                        <i className="mdi mdi-comment-processing-outline" />
                        <span className="hide-menu">Organization details</span>
                      </NavLink>
                    </li>

                    {/*Logout*/}
                    <li
                      onClick={scrollTop}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          scrollTop();
                        }
                      }}
                      className="sidebar-item"
                      key="#logout"
                      tabIndex={0} // Make the li focusable
                      role="button"
                    >
                      <Fragment>
                        {isAuthenticated ? authLinks : guestLinks}
                      </Fragment>
                    </li>
                  </ul>
                </Collapse>
              </li>
              {/* *************Manage account ends here ***********************/}
            </Nav>
            {/* <ModeTonggle/>  */}
          </PerfectScrollbar>
        )}
        <Footer />
        <Modal
          style={{ maxWidth: "40%" }}
          isOpen={reportModal}
          toggle={closeReportModal}
        >
          <ModalHeader toggle={closeReportModal}>
            Facing some issue? Let us know.
          </ModalHeader>
          <ModalBody>
            {ticketSubmit ? <Alert>Submitted successfully!</Alert> : ""}
            <Form onSubmit={onReportSubmit}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Email address</Label>
                    <Input
                      type="text"
                      value={ls.get("email")}
                      name="emailId"
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Row></Row>
                {/* <Col md="6">
									<FormGroup>
										<Label>Session ID</Label>
										<Input type="text" value={""} disabled />
									</FormGroup>
								</Col> */}
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>Comment</Label>
                    <Input
                      type="textarea"
                      onChange={(e) => onChange(e)}
                      placeholder="Please describe your issue in detail"
                      name="comment"
                      className={
                        "form-control" + (err.comment ? " is-invalid" : "")
                      }
                    />
                    {err.comment ? (
                      <div className="invalid-feedback ">{err.comment}</div>
                    ) : (
                      ""
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Button
                type="submit"
                color="primary"
                className="raised-btn-success"
              >
                Submit
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </aside>
  );
};

//export default Sidebar;

Sidebar.propTypes = {
  //searchTable:  PropTypes.func,
  logout: PropTypes.func.isRequired,
  roles: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  submitFeedbackData: PropTypes.func.isRequired,
  //  header: PropTypes.object.isRequired,
  // apiref :  PropTypes.func.isRequired,
  //  getReviewPending:  PropTypes.func.isRequired,
  //  getApproved:  PropTypes.func.isRequired,
  //  getRejected:  PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated,
  roles: state.auth.roles,
  header: state.header,
});

export default connect(mapStateToProps, { logout, submitFeedbackData })(
  Sidebar
);
