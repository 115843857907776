import {
  GET_USER,
  GET_USERS,
  ADD_USER,
  EDIT_USERS,
  DELETE_USERS,
  CLEAR_USER,
  GET_USERS_ERROR,
  ADD_USER_ERROR,
  STOP_USER_ALERT,
  EDIT_USER,
  TRANSFER_OWNERSHIP,
} from "../actions/types";

const initialState = {
  users: [],
  user: null,
  users_error: null,
  loading: true,
  subheading: "",
  usererror: "",
  userdelerror: false,
  adduseralert: false,
  addusererror: false,
  edituseralert: false,
  transferOwnershipAlert: false,
  ownerEmail:""
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_USER:
      return {
        ...state,
        // posts: [payload.data, ...state.posts],
        adduseralert: true,
        addusererror: false,
        userdelerror: false,
        loading: false,
        subheading: "Users",
        edituser: false,
      };

    case EDIT_USER:
      return {
        edituseralert: true,
        users: [],
      };

    case GET_USERS:
      return {
        ...state,
        users: payload.workers,
        users_error: payload.error,
        loading: false,
        subheading: "Users",
        ownerEmail: payload.ownerEmail
      };

    case ADD_USER_ERROR:
      return {
        ...state,
        usererror: payload.data.message,
        userdelerror: false,
        adduseralert: false,
        addusererror: true,
      };

    case GET_USERS_ERROR:
      return {
        ...state,
        users_error: payload.error,
        loading: false,
        subheading: "Users",
      };

    case GET_USER:
      return {
        ...state,
        user: payload,
        loading: false,
        // subheading: "Users"
      };

    case DELETE_USERS:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== payload),
        usererror: false,
        userdelerror: true,
        adduseralert: false,
        addusererror: false,
        loading: false,
      };

    case EDIT_USERS:
      return {
        ...state,
        user: payload,
        loading: false,
      };

    case CLEAR_USER:
      return {
        ...state,
        users: [],
        user: null,
        subheading: "",
        loading: true,
      };

    case TRANSFER_OWNERSHIP:
      return {
        ...state,
        transferOwnershipAlert: true,
        users: [],
      };
    case STOP_USER_ALERT:
      return {
        userdelerror: false,
        users_error: false,
        adduseralert: false,
        addusererror: false,
        edituseralert: false,
      };

    default:
      return state;
  }
}
