import {
    GET_LOGS_DATA,
    GET_LOGS_FORM,
   
} from '../actions/types'

const initialState = {
    
    logTab: true,
   
}

export default function (state = initialState, action) {
    const { type } = action
    switch (type) {

        case GET_LOGS_DATA:
            return {
                logTab: true,
            }
      
        case GET_LOGS_FORM:
            return {
                logTab: false,
            };
        


        default:
            return state;
    }
}