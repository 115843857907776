import axios from "axios";
//import { setAlert } from './alert';
import { GET_TEST_PREVIEW, GET_SEND_REQUEST, GET_INVITE_LINK, REQ_ERROR, REQ_LOADER } from "./types";
import { env as environment } from "./environment";
import refreshAuth from "./refreshAuth";
//var apikey = ls.get('apikey');
import ls from "localstorage-slim";

ls.config.encrypt = true;

let getInvLnkCount = 0;
export const getInviteLink = (json) => async (dispatch) => {
	dispatch({ type: REQ_LOADER });
	try {
		const res = await axios.post(environment.getverificationlink, json);
		//console.log(res.data);
		if (res.data) { getInvLnkCount = 0; }
		dispatch({
			type: GET_INVITE_LINK,
			payload: res.data,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error") && getInvLnkCount < 5) {
			getInvLnkCount++;
			dispatch(refreshAuth("getInviteLink", json));
		} else {
			getInvLnkCount = 0;
			dispatch({
				type: REQ_ERROR,
				payload: err.response,
			});
		}
	}
};

export const getTestPreview = (json) => async (dispatch) => {
	dispatch({ type: REQ_LOADER });
	try {
		const res = await axios.post(environment.getverificationlink, json);
		console.log(res.data.verificationlink);

		dispatch({
			type: GET_TEST_PREVIEW,
			payload: res.data,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getTestPreview", json));
		} else {
			dispatch({
				// type: REQ_ERROR,
				// payload: err.response
			});
		}
	}
};

export const sendRequest = (json) => async (dispatch) => {
	dispatch({ type: REQ_LOADER });

	try {
		const res = await axios.post(environment.sendsmsfororg, json);
		//console.log(res.data);
		dispatch({
			type: GET_SEND_REQUEST,
			payload: res.data,
		});
	} catch (err) {
		//console.log(err)
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("sendRequest", json));
		} else {
			dispatch({
				type: REQ_ERROR,
				payload: err.response,
			});
		}
	}
};
