import { combineReducers } from "redux";
import settings from "./settings";
import auth from "./auth";
import alert from './alert'
import table from './table'
import button from './button'
import logs from "./logs";
import org from "./org";
import billing from  './billing';
import header from  './header';
import users from  './users';
import logform from  './logform';
import reqdoc from  './reqdoc';
import viewdoc from './viewdoc';
import mode from './mode';
import errors from './errors';
const Reducers = combineReducers({
  settings,
  auth,
  alert,
  table,
  button,
  logs,
  org,
  billing,
  header,
  users,
  logform,
  reqdoc,
  viewdoc,
  mode,
  errors
});

export default Reducers;
