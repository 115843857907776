import {
    GET_LOGS,
    LOGS_ERROR,
    CLEAR_LOG,
    // CLEAR_TABLE
    HEADER_SEARCH_INPUT
} from '../actions/types'

const initialState = {
    requestLogs: [],
    loading: false,
    error: {},
    searchHeaderInput:"",

}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {

        case GET_LOGS:
            console.log("inside get logs reducer")
            return {
                
                ...state,
                requestLogs: payload,
                loading: false
            }
      
        case LOGS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        case CLEAR_LOG:
            return {
                ...state,
                requestLogs: [],
                loading: true
            }
        //  case CLEAR_TABLE:
        //     return{
        //         ...state,
        //         requestLogs: [],
        //         loading: true
        //     }
        case HEADER_SEARCH_INPUT:
            return {
                ...state,
                searchHeaderInput: action.payload
            };

        default:
            return state;
    }
}