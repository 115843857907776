import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import FullLayout from "../layouts/FullLayout";
import PrivateRoute from "./Protected";
import NotFound from '../layouts/layout-components/NotFound';
import IndexRoutes from "./index";


//import BlankLayout from "../layouts/BlankLayout";
// import FullAuthLayout from "../layouts/FullAuthLayout";
//import Alert from '../layouts/Alert';


const Routes = () => {
  return (
    
    <BrowserRouter>
      <Switch>
        {/* <Alert /> */}
        {/* <Route exact path="/Login" component={BlankLayout} />; */}
        {IndexRoutes.map((prop, key) => {
          return (
            <PrivateRoute
              path={prop.path}
              key={key}
              component={prop.component}
            />
            // <PrivateRoute path="/pdf/:file" key="/pdf/256" component={Pdf} />
        
          );
        })}
        {/* <Route exact path="/pdf/:file" component={Pdf}/> */}
        <Route path="/client/admin-dash" component={FullLayout} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
