import axios from "axios";
//import { setAlert } from './alert';
import {
	GET_PDF_DATA,
	GET_DOCUMENT_DATA,
	VIEW_DOC_LOADER,
	REQ_ERROR,
	GET_REJECT_DATA,
	GET_APPROVE_DATA,
	GET_VIEW_DOC_MESSAGE,
	REMOVE_VIEW_DOC_MESSAGE,
	S3_BUCKET_DATA,
} from "./types";
import { env as environment } from "./environment";
import refreshAuth from "./refreshAuth";
import { sendLogs } from "../actions/logs";
import ls from "localstorage-slim";

ls.config.encrypt = true;
export const getDownloadDocument = (sessionId) => async (dispatch) => {
	dispatch({ type: VIEW_DOC_LOADER });
	var Downloadjson = { sessionid: sessionId };
	if (ls.get("authMode") === "2") {
		axios.defaults.headers.common["Authorization"] = ls.getI("tokenTest");
	} else axios.defaults.headers.common["Authorization"] = ls.get("token");
	try {
		const res = await axios.post(environment.download, Downloadjson);
		console.log("data1:", res.data);
		console.log("sessID", sessionId);
		if (res.data.message === "You are not allowed to see this document!") {
			console.log("data2:", res.data);
			dispatch({
				type: GET_VIEW_DOC_MESSAGE,
				payload: res.data,
			});
		} else {
			sendLogs("getDownloadedDocument", "getDownloadedDocument success", "actions/viewdoc.js");
			dispatch({
				type: GET_PDF_DATA,
				payload: res.data,
			});
		}
	} catch (err) {
		console.log(err);
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getDownloadDocument", Downloadjson));
		} else {
			sendLogs("getDownloadedDocument Failed", err.response, "actions/viewdoc.js");

			dispatch({
				type: REQ_ERROR,
				payload: err.response,
			});
		}
	}
};

export const getLastClickedDocument = (sessionId) => async (dispatch) => {
	dispatch({ type: VIEW_DOC_LOADER });
	var lastClickedjson = { sessionid: sessionId };
	try {
		const res = await axios.post(environment.get_lastclicked_link, lastClickedjson);
		console.log(res.data);
		sendLogs("getLastClickedDocument", "getLastClickedDocument success", "actions/viewdoc.js");

		dispatch({
			type: GET_DOCUMENT_DATA,
			payload: res.data,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getLastClickedDocument", lastClickedjson));
		} else {
			sendLogs("getLastclickedDocument Failed", err.response, "actions/viewdoc.js");
			dispatch({
				type: REQ_ERROR,
				payload: err.response,
			});
		}
	}
};
export const getVideos = () => async (dispatch) => {
	// const session = { sessionId: id };
	try {
		const res = await axios.post(environment.getS3bucket);
		dispatch({
			type: S3_BUCKET_DATA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};
export const getVideosById = (id) => async (dispatch) => {
	const session = { id: id };
	try {
		const res = await axios.post(environment.searchS3bucket, session);
		dispatch({
			type: S3_BUCKET_DATA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const approveDocument = (json) => async (dispatch) => {
	// dispatch({ type: VIEW_DOC_LOADER });

	try {
		const res = await axios.post(environment.verifykyc, json);
		console.log(res.data);
		//   dispatch({
		//    type: GET_APPROVE_DATA,
		//    payload: res.data
		//  });
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("approveDocument", json));
		} else {
			dispatch({
				type: REQ_ERROR,
				payload: err.response,
			});
		}
	}
};

export const rejectDocument = (json) => async (dispatch) => {
	try {
		const res = await axios.post(environment.verifykyc, json);
		console.log(res.data);
		//   dispatch({
		//    type: GET_REJECT_DATA,
		//    payload: res.data
		//  });
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("rejectDocument", json));
		} else {
			dispatch({
				type: REQ_ERROR,
				payload: err.response,
			});
		}
	}
};

export const closeapproveDocument = () => async (dispatch) => {
	dispatch({
		type: GET_APPROVE_DATA,
	});
};

export const closeRejectDocument = () => async (dispatch) => {
	dispatch({
		type: GET_REJECT_DATA,
	});
};

export const closeSweetalert = () => async (dispatch) => {
	dispatch({
		type: REMOVE_VIEW_DOC_MESSAGE,
	});
};
