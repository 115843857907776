import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Switch from "react-switch";
import { Button, Modal, ModalBody } from "reactstrap"; // Import Modal components
import ls from "localstorage-slim";
import { changeModeLogout, switchModeServer } from "../../../actions/auth";
import { useLocation } from "react-router-dom";
import exclamation from "../../../assets/images/icon/exclamation.png";
import FeatherIcon from "feather-icons-react";
import { grey } from "@material-ui/core/colors";
ls.config.encrypt = true;

const ModeTonggle = ({ changeModeLogout, switchModeServer, authMode, auth, history }) => {
  const [rSelected, setRSelected] = useState(authMode);
  const [modalOpen, setModalOpen] = useState(false); // Modal visibility
  const [switchToProduction, setSwitchToProduction] = useState(false);
  const [switchedToModeMessage, setSwitchedToModeMessage] = useState("");
  const [toggleSwitch, setToggleSwitch] = useState(true);
  const location = useLocation();

  const toggleModal = () => {
    setModalOpen(!modalOpen); // Toggle modal visibility
  }
  // #sidebarnav > li:nth-child(8) > div > div > div > div.react-switch-handle
  const onRadioBtnClick = () => {
    console.log("Switch clicked!");
    setModalOpen(true); // Show the modal
    setSwitchToProduction(!switchToProduction);
  };

  useEffect(() => {
    const currentUrl = location.pathname;
    if (currentUrl.includes("/button-setting")) {
      setToggleSwitch(false);
    } else {
      setToggleSwitch(true);
    }
  }, [location]);

  const handleSwitchConfirmation = async (confirmed) => {
    if (confirmed) {
      if (rSelected === 1) {
        let data = { email: auth.user.email, sandbox: false };
        await switchModeServer(data);
        await changeModeLogout(2);
        setSwitchedToModeMessage("Switched to test account successfully!");
        ls.set("apikey", ls.get("sandboxapi"));
        ls.set("secrettoken", ls.get("tokenTest"));
      } else {
        let data = { email: auth.user.email, sandbox: true };
        await switchModeServer(data);
        await changeModeLogout(1);
        setSwitchedToModeMessage("Switched to production account successfully!");
        ls.set("apikey", ls.get("liveapi"));
        ls.set("secrettoken", ls.get("tempsecret"));
      }
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    setModalOpen(false); // Close the modal
  };

  useEffect(() => {
    setRSelected(authMode);
  }, [authMode]);

  return (
    <Fragment>
      {toggleSwitch && (
        <div
          className={
            rSelected === 2 ? "raised-mode-toggle toggleContainer1" : "raised-mode-toggle toggleContainer2"
          }
        >  
        <FeatherIcon  className={rSelected === 2 ? "toggleTextActive" : "toggleTextInActive"} icon="repeat"/>
          <small
            className={rSelected === 2 ? "toggleTextActive" : "toggleTextInActive"}
            style={{ width: "80px", fontSize: "15px", marginLeft: "-1px" , cursor: "default"}}
          >
           <span className="hide-menu">
           Test mode
            </span>
          </small>
          <Switch 
          // className="toggle-switch"
            onColor="#fcaf10"
            handleDiameter={16}
            uncheckedIcon={false}
            height={18}
            width={38}
            onChange={onRadioBtnClick}
            checked={rSelected === 2}
            activeBoxShadow="rgb(51, 187, 255) 0px 0px 0px 0px"
          />
        {/* <Switch
                        className="ml-10 mr-3"
                        onColor="#00bbd4"
                        // onHandleColor="#2693e6"
                        handleDiameter={16}
                        uncheckedIcon={false}
                        // checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 2)"
                        // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={18}
                        width={38}
                        onChange={this.handleResubmission}
                        defaultValue={this.state.resubmission}
                        checked={this.state.resubmission}
                      /> */}
        </div>
      )}

      {/* Modal for switch confirmation */}
      
      <Modal isOpen={modalOpen} toggle={toggleModal} centered> {/* Add 'centered' to center the modal vertically */}
  <ModalBody className="text-center"> {/* Use 'text-center' to center text */}

    <div >
       <img src={exclamation} alt="Exclamation" style={{margin:"20px auto", width:"90px"}}/>  
    </div>
  <h2 style={{ textAlign: 'center', fontSize: '22px', marginBottom: '20px' }}>

    {`Do you want to switch to ${rSelected === 1 ? "test" : "production"} account?`}
      </h2>

      <div className="d-flex justify-content-center mt-4 mb-4">
  <Button color="primary" className="mx-2 btn btn-lg btn-primary " onClick={() => handleSwitchConfirmation(true)}>
    Yes
  </Button>
  <Button color="secondary" className="mx-2 btn btn-lg btn-secondary " onClick={toggleModal} >
    No
  </Button>
</div>

  </ModalBody>
  

      
      </Modal>

      {/* SweetAlert for displaying mode switch message */}
      {switchedToModeMessage && (
          <Modal 
          isOpen={!!switchedToModeMessage}  
          toggle={() => setSwitchedToModeMessage("")} centered>
      <ModalBody className="text-center">
           <div className="dummy-positioning ">  
          <div className="success-icon" style={{transform: "scale(0.7)",    border: "4px solid green"}}>
            <div className="success-icon__tip" style={{background:"green",height:"8px"}}> </div>
            <div className="success-icon__long" style={{background:"green",height:"9px"}}></div>
          </div>
          
        </div>
        <h2 style={{ textAlign: 'center', fontSize: '22px', marginBottom: '25px' ,marginTop:"15px"}}>
          {switchedToModeMessage}
          </h2>
      </ModalBody>
    </Modal>

      )}
    </Fragment>
  );
};

ModeTonggle.propTypes = {
  changeModeLogout: PropTypes.func.isRequired,
  switchModeServer: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  authMode: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  authMode: state.auth.authMode,
});

export default connect(mapStateToProps, { changeModeLogout, switchModeServer })(ModeTonggle);
