import {
	GET_BUTTON,
	GET_BUTTONS,
	POST_BUTTON,
	CLEAR_BUTTON,
	ADD_BUTTON,
	DELETE_BUTTON,
	REMOVE_BUTTON_ALERT,
	GET_API_BUTTON,
	UPDATE_BUTTON,
	LOAD_BUTTON,
	GET_MASTERFIELDDATA,
	GET_EMAILREMINDER_DATA,
	TEST_EMAILREMINDER,
	LOAD_COUNTRY_LIST,
	GET_COUNTRY_LIST,
	ERR_COUNTRY_LIST,
	LOAD_COUNTRY_LINKS,
	GET_COUNTRY_LINKS,
	ERR_COUNTRY_LINKS,
	RESET_COUNTRY,
	RESET_DIRECT_LINK,
	SET_ALPHA_CODE,
	SET_CATEGORY,
	BUTTON_DELETE,
	BUTTON_DEL_SUCCESS,
	BUTTON_DEL_ERROR,
	DELETE_TABLE_DATA_SUCCESS,
	UPDATE_TABLE_DATA,
	CREATE_SOURCE
} from "../actions/types";
import ls from "localstorage-slim";

ls.config.encrypt = true;
const initialState = {
	buttons: [],
	button: null,
	btn: "",
	firstbtnid: null,
	buttonid: null,
	loading: true,
	deleteAlert: false,
	deleteMsg: null,
	btnsuccess: false,
	loadingTestEmailReminder: true,
	error: {},
	Tag: [],
	emailreminderdata: [],
	testEmailReminderResponse: "",
	countryList: {
		loader: false,
		data: null,
		err: null,
	},
	countryLinks: {
		searching: false,
		loader: false,
		data: null,
		err: null,
	},
	btnSettingDelBtn: { num: 0, title: null, msg: null, reset: null },
	deleteTableData:false,
	updateTableData:false,
	createSource:false,
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_API_BUTTON:
			return {
				...state,
				button: payload.payload.btndata,
				buttonid: payload.buttonid,
				loading: false,
			};

		case GET_BUTTON:
			return {
				...state,
				...payload,
				btn: payload.btndata,
				// btnsuccess:true,
				loading: false,
			};

		case UPDATE_BUTTON:
			return {
				...state,
				...payload,
				btn: payload.data,
				btnsuccess: true,
				loading: false,
			};

		case ADD_BUTTON:
			return {
				...state,
				// buttons: payload.data,
				buttonid: payload,
				// ...state,
				// buttons: [payload, ...state.buttons],
				loading: true,
				deleteAlert: false,
			};

		case GET_BUTTONS:
			//console.log("first button id " + payload.data[0].buttonid);
			const firstButton = payload.data.reverse();
			ls.set("firstbtnid", firstButton[0]?.buttonid);
			payload.data.sort((a, b) => b.btndata.eptime - a.btndata.eptime);
			return {
				...state,
				buttons: payload.data,
				firstbtnid: payload.data[0]?.buttonid,
				loading: false,
			};

		case POST_BUTTON:
			return {
				...state,
				button: payload.data,
				loading: false,
			};

		case CLEAR_BUTTON:
			return {
				...state,
				buttons: [],
				button: null,
				loading: true,
			};

		case LOAD_BUTTON:
			return {
				...state,
				loading: true,
				deleteAlert: false,
				deleteMsg: null,
				btnsuccess: false,
			};

		case DELETE_BUTTON:
			//console.log(payload);
			//console.log(payload.message);
			return {
				...state,
				deleteMsg: payload.message,
				deleteAlert: true,
				loading: false,
			};

		case REMOVE_BUTTON_ALERT:
			return {
				//  ...state,
				deleteMsg: null,
				deleteAlert: false,
				//loading: false,
			};
		case GET_MASTERFIELDDATA:
			return {
				...state,
				Tag: payload.fieldx,
			};
		case GET_EMAILREMINDER_DATA:
			return {
				...state,
				emailreminderdata: payload,
			};
		case TEST_EMAILREMINDER:
			return {
				...state,
				testEmailReminderResponse: payload,
				loadingTestEmailReminder: false,
			};
			
		case LOAD_COUNTRY_LIST:
			return {
				...state,
				countryList: {
					loader: true,
					data: null,
					err: null,
				},
			};
		case GET_COUNTRY_LIST:
			return {
				...state,
				countryList: {
					loader: false,
					data: payload,
					err: null,
				},
			};
		case ERR_COUNTRY_LIST:
			return {
				...state,
				countryList: {
					loader: false,
					data: null,
					err: payload,
				},
			};
		case LOAD_COUNTRY_LINKS:
			return {
				...state,
				btnsuccess: false,
				countryLinks: {
					loader: true,
					data: null,
					err: null,
					searching: false,
				},
			};
		case GET_COUNTRY_LINKS:
			return {
				...state,
				countryLinks: {
					loader: false,
					data: payload.res,
					err: null,
					searching: payload.searching,
				},
				btn: {
					...state.btn,
					coverage: {
						...state.btn.coverage,
						category: payload.cat,
					}
				}
			};
		case ERR_COUNTRY_LINKS:
			return {
				...state,
				countryLinks: {
					loader: false,
					data: null,
					err: payload,
				},
			};
		case RESET_COUNTRY:
			return {
				...state,
				countryLinks: {
					loader: false,
					data: null,
					err: null,
				}
			};
		case RESET_DIRECT_LINK:
			return {
				...state,
				btn: {
					...state.btn,
					coverage: {
						...state.btn.coverage,
						direct_link: "",
					}
				}
			};
		case SET_ALPHA_CODE:
			return {
				...state,
				btndata: {
					...state.btndata,
					country: payload.item,
					alpha2code: payload.alpha,
				}
			};
		case SET_CATEGORY:
			return {
				...state,
				btn: {
					...state.btn,
					coverage: {
						...state.btn.coverage,
						category: payload,
					}
				}
			};
		case BUTTON_DELETE:
			return {
				...state,
				btnSettingDelBtn: {
					...state.btnSettingDelBtn,
					num: payload.num,
					title: payload.title,
					msg: payload.msg,
					reset: null,
				},
			};
		case BUTTON_DEL_SUCCESS:
			return {
				...state,
				btnSettingDelBtn: {
					...state.btnSettingDelBtn,
					num: 0,
					title: null,
					msg: null,
					reset: null,
				},
			};
		case BUTTON_DEL_ERROR:
			return {
				...state,
				btnSettingDelBtn: {
					...state.btnSettingDelBtn,
					num: payload.num,
					title: payload.title,
					msg: payload.msg,
					reset: payload.reset,
				},
			};

			
			  case DELETE_TABLE_DATA_SUCCESS:
				return {
				  ...state,
				 deleteTableData:!state.deleteTableData
				};
			case UPDATE_TABLE_DATA:
				return{
					...state,
					updateTableData:!state.updateTableData

				};
				case CREATE_SOURCE:
					return {
						...state,
					   createSource:!state.createSource
					  };

		default:
			return state;
	}

	
}
