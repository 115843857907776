import {
	GET_PDF_DATA,
	GET_DOCUMENT_DATA,
	VIEW_DOC_LOADER,
	GET_APPROVE_DATA,
	GET_REJECT_DATA,
	GET_VIEW_DOC_MESSAGE,
	REMOVE_VIEW_DOC_MESSAGE,
	S3_BUCKET_DATA,
} from "../actions/types";

const initialState = {
	viewpdfdata: "",
	viewdata: "",
	view_loading: false,
	viewdoc_msg: false,
	s3_data: [],
	error: {},
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case VIEW_DOC_LOADER:
			return {
				view_loading: true,
			};

		case GET_REJECT_DATA:
			window.open("/client/documents-received/review-pending", "_self");
			return {};

		case GET_APPROVE_DATA:
			window.open("/client/documents-received/review-pending", "_self");
			return {};

		case GET_PDF_DATA:
			return {
				// ...payload,
				viewpdfdata: payload,
				view_loading: false,
			};

		case GET_DOCUMENT_DATA:
			return {
				// ...payload,
				viewdata: payload,
				view_loading: false,
			};

		case GET_VIEW_DOC_MESSAGE:
			return {
				viewdoc_msg: true,
			};
		case REMOVE_VIEW_DOC_MESSAGE:
			return {
				viewdoc_msg: false,
			};
		case S3_BUCKET_DATA:
			return {
				...state,
				// loading: false,
				s3_data: payload,
			};
		default:
			return state;
	}
}
